import styled from "styled-components";

export const Heading1 = styled.h1`
  /* font-family: "Cormorant", serif; */
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 60px;
  color: var(--text);

  @media screen and (max-width: 990px) {
    font-size: 45px;
  }
`;

export const Heading2 = styled.h1`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
  letter-spacing: 0.05em;
  color: var(--text);

  @media screen and (max-width: 500px) {
    font-size: 35px;
  }
`;

export const Heading3 = styled.h3`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: var(--text);
  margin-bottom: 10px;
`;

export const Heading4 = styled.h4`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 44px;
  color: black;
`;

export const Heading5 = styled.h5`
  font-family: "Righteous", cursive;
  font-weight: 300;
  font-size: 30px;
`;

export const Heading6 = styled.h6`
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin-top: 25px;
  color: var(--text);

  @media screen and (max-width: 990px) {
    font-size: 12px;
  }
`;
