import { Navigation } from "../../components/navigation/navigation";
import { TextHeader } from "../../components/text-header/text-header";
import { ContentSection, PageWrapper } from "../../theme/layout/wrappers";
import {
  Heading2,
  Heading3,
  Heading4,
  Heading6,
} from "../../theme/typography/headers";

export function OurConstitutionPage(): JSX.Element {
  return (
    <PageWrapper className="white">
      <TextHeader
        pageTitle="Constitution of Dramabuzz Senior Theatre Group"
        hideBottomImage
      />

      <hr />

      <Navigation />

      <ContentSection>
        <Heading2>Article I: Name and Purpose</Heading2>

        <Heading3>Section 1. Name</Heading3>
        <Heading4>
          The name of this organization shall be Dramabuzz Senior Theatre Group,
          hereinafter referred to as "Dramabuzz Senior" or "the Group."
        </Heading4>

        <Heading3>Section 2. Purpose</Heading3>
        <Heading4>
          Dramabuzz Senior is a non-profit theatre group committed to producing
          quality theatrical productions while upholding Christian principles
          and values. The Group provides opportunities for adults aged 18-30+ to
          participate in theatrical productions in a supportive and professional
          environment.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Article II: Governance</Heading2>

        <Heading3>Section 1. Directorial Committee</Heading3>
        <Heading6>
          The Group shall be governed by a Directorial Committee consisting of
          four (4) members:
        </Heading6>
        <Heading4>
          <ol>
            <li>
              Three (3) internal directors who are directly involved in
              production management
            </li>
            <li>
              One (1) external neutral member to provide unbiased oversight
            </li>
          </ol>
        </Heading4>

        <Heading3>Section 2. Committee Powers and Responsibilities</Heading3>
        <Heading6>The Directorial Committee shall:</Heading6>
        <Heading4>
          <ol>
            <li>
              Make all administrative decisions regarding the Group's operations
            </li>
            <li>Oversee the financial management of productions</li>
            <li>Appoint production staff and creative teams</li>
            <li>Have final authority in all matters concerning the Group</li>
            <li>
              Maintain fairness and consistency in applying rules and
              regulations
            </li>
            <li>Provide financial reports upon request</li>
          </ol>
        </Heading4>
      </ContentSection>

      {/* <ButtonBlock
        buttonLabel="Book Your Tickets"
        href="https://www.quicket.co.za/events/251146-toad-of-toad-hall/#/"
        theme="primary"
      >
        Tickets for Toad of Toad Hall are now on sale on Quicket. Don't miss
        Dramabuzz Senior's third show at{" "}
        <strong>Gereformeerde Kerk Noordrand</strong>.
      </ButtonBlock> */}
    </PageWrapper>
  );
}
