import { Navigation } from "../../components/navigation/navigation";
import { TextHeader } from "../../components/text-header/text-header";
import { ContentSection, PageWrapper } from "../../theme/layout/wrappers";
import {
  Heading2,
  Heading3,
  Heading4,
  Heading6,
} from "../../theme/typography/headers";

export function OurPoliciesPage(): JSX.Element {
  return (
    <PageWrapper className="white">
      <TextHeader pageTitle="Our Policies" hideBottomImage />

      <hr />

      <Navigation />

      <ContentSection>
        <Heading2>Introduction</Heading2>

        <Heading4>
          This Policy Document serves as a comprehensive guide to the
          operational procedures and expectations of the Dramabuzz Senior
          Theatre Group (hereafter referred to as "the Group"). It should be
          read in conjunction with the Group Constitution and provides detailed
          clarification of the rules and procedures outlined therein.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Requirements for Membership</Heading2>
        <Heading3>Member Age</Heading3>

        <Heading4>
          The Group welcomes participants aged 18 and above, with no upper age
          limit. All prospective Members must be of eligible age by the time of
          performance.
        </Heading4>

        <Heading3>Member Attendance</Heading3>

        <Heading4>
          Our attendance policy reflects our commitment to professional
          standards and respect for all Members' time. The Group operates with
          the following attendance requirements:
        </Heading4>

        <Heading4>
          Members must maintain an 85% attendance rate for regular rehearsals.
          This requirement exists to ensure consistent progress and maintain the
          quality of our productions. The attendance calculation includes all
          regular Tuesday evening rehearsals and any additional scheduled
          rehearsals. The Group Constitution outlines how attendance is
          calculated.
        </Heading4>

        <Heading4>
          For workshop days and specially arranged rehearsals, particularly
          those in the final two weeks before performances, attendance is
          mandatory. These sessions are crucial for production cohesion and
          final preparations. Workshop days and specially arranged rehearsals
          are any rehearsals outside of the regular weekly rehearsals and
          predetermined extra rehearsals laid out at the beginning of the year.
        </Heading4>

        <Heading3>Member Absence</Heading3>

        <Heading4>
          The Group maintains strict protocols regarding rehearsal absences to
          ensure fair treatment and clear communication:
        </Heading4>

        <Heading6>Non-Emergency Absences:</Heading6>

        <Heading4>
          <ul>
            <li>Members must provide two weeks' advance notice</li>
            <li>
              Approval is not guaranteed for social events, family functions,
              parties, or dinners
            </li>
            <li>
              Each request will be evaluated based on production schedules and
              requirements
            </li>
          </ul>
        </Heading4>

        <Heading6>Standing Commitments:</Heading6>

        <Heading4>
          <ul>
            <li>
              Work-related commitments must be formally arranged with the
              Directors
            </li>
            <li>
              Regular obligations (such as standby duties) require explicit
              Director approval
            </li>
            <li>
              All approved standing arrangements will be communicated to the
              full cast
            </li>
          </ul>
        </Heading4>

        <Heading6>Illness-Related Absences:</Heading6>

        <Heading4>
          <ul>
            <li>
              First occurrence requires notification before 17:00 on the day of
              the practice
            </li>
            <li>Subsequent absences must be supported by a doctor's note</li>
            <li>
              Failure to follow these procedures will result in disciplinary
              action
            </li>
          </ul>
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Standards for Discipline</Heading2>
        <Heading3>Warning Slips</Heading3>

        <Heading4>
          The Group employs a two-tier warning system using colored slips to
          maintain order and ensure professional standards:
        </Heading4>

        <Heading6>Pink Slips (Preparation Warnings):</Heading6>
        <Heading4>
          <ul>
            <li>Issued for failure to meet line memorization deadlines</li>
            <li>Two Pink Slips result in temporary suspension</li>
            <li>Three Pink Slips lead to immediate expulsion</li>
          </ul>
        </Heading4>

        <Heading6>Orange Slips (Behavioral Warnings):</Heading6>
        <Heading4>
          <ul>
            <li>Issued for punctuality violations and conduct infractions</li>
            <li>Cover unauthorized conversations during rehearsal</li>
            <li>Include unexcused tardiness or absences</li>
            <li>Three Orange Slips result in temporary suspension</li>
            <li>Four Orange Slips lead to immediate expulsion</li>
          </ul>
        </Heading4>

        <Heading3>Member Expulsion</Heading3>

        <Heading6>
          Our expulsion policy ensures accountability while maintaining fair
          opportunities for improvement:
        </Heading6>
        <Heading4>
          <ul>
            <li>
              Expelled Members may reapply to join at the start of the next
              season
            </li>
            <li>
              Multiple expulsions result in permanent membership prohibition
            </li>
            <li>
              Simultaneous expulsion for both Pink and Orange Slip violations
              counts as two separate expulsions
            </li>
          </ul>
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Standards for Professional Conduct</Heading2>
        <Heading4>
          These rules help to govern how we operate as a Group. Failure to keep
          to these rules will most likely result in Pink or Orange Slips.
        </Heading4>

        <Heading3>Rehearsal Environment</Heading3>
        <Heading6>
          To maintain a professional and productive environment:
        </Heading6>
        <Heading4>
          <ul>
            <li>Rehearsal times are strictly 19:00 to 21:30 on Tuesdays</li>
            <li>
              Members must arrive promptly and be prepared to begin at the
              designated time
            </li>
            <li>
              Cell phones must remain out of sight unless specifically permitted
              by the Directors
            </li>
            <li>
              Cell phones, tablets or laptops will not be permitted for use as
              scripts
            </li>
            <li>Eating is restricted to designated break periods</li>
            <li>
              All communication during rehearsal must be directed to the
              Directors
            </li>
            <li>
              Breaks will be worked into the schedule to give the Members time
              to socialize and to eat
            </li>
          </ul>
        </Heading4>

        <Heading3>Behaviour</Heading3>
        <Heading6>
          In order to keep the rehearsals in order and appropriate for all the
          Members of the Group:
        </Heading6>
        <Heading4>
          <ul>
            <li>
              No vulgar language or crude humor will be permitted during
              rehearsal times
            </li>
            <li>
              The bullying of cast Members will not be permitted under any
              circumstances. This includes verbal and physical abuse. Bullying
              will result in immediate disciplinary action from the Directors
            </li>
            <li>
              Modest clothing, as laid out in the dress code, is to be worn by
              both sexes
            </li>
            <li>
              Cast Members will not be permitted to enter the change room of the
              opposite sex at any point
            </li>
            <li>
              Cast Members are to submit to the authority and vision of the
              Directors
            </li>
            <li>Cast Members are to maintain good standing with the Group</li>
          </ul>
        </Heading4>

        <Heading3>Dress Code</Heading3>
        <Heading6>
          Members must maintain appropriate attire during all Group activities:
        </Heading6>
        <Heading4>
          <ul>
            <li>Clothing must be modest and suitable for movement</li>
            <li>
              Abdominal areas, cleavage, and waistlines must remain covered
            </li>
            <li>Black, comfortable clothing is recommended</li>
            <li>Clothing with inappropriate or lewd graphics is prohibited</li>
            <li>
              Violations will result in warnings and potential disciplinary
              action
            </li>
          </ul>
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Standards for Production Participation</Heading2>
        <Heading3>Role Assignment</Heading3>

        <Heading6>
          The Group maintains clear guidelines for role distribution:
        </Heading6>
        <Heading4>
          <ul>
            <li>New Members typically begin in chorus or supporting roles</li>
            <li>All role assignments are at the Directors' discretion</li>
            <li>Members must accept their assigned roles regardless of size</li>
            <li>
              Romantic scenes may be assigned regardless of marital status
            </li>
          </ul>
        </Heading4>

        <Heading3>Production Support</Heading3>

        <Heading6>
          Members are expected to contribute to production success through:
        </Heading6>
        <Heading4>
          <ul>
            <li>Meeting all line memorization deadlines</li>
            <li>Participating in work parties and props days when possible</li>
            <li>Contributing to technical and backstage operations</li>
            <li>
              Maintaining professional conduct throughout the production process
            </li>
          </ul>
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Financials</Heading2>
        <Heading6>
          The Group operates on a self-funded basis with the following financial
          requirements:
        </Heading6>
        <Heading4>
          <ul>
            <li>Membership fee of R500.00 per production season</li>
            <li>Payment arrangements available upon Director approval</li>
            <li>All financial records maintained for transparency</li>
            <li>Financial reports available upon request</li>
          </ul>
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Source of Authority</Heading2>

        <Heading4>
          The Policy Document is considered supplemental to the Group
          Constitution. All policies and rules contained within this document
          are binding. However, where any points of the Group Constitution
          contradict the Policy Document, the Group Constitution is to be
          preferred.
        </Heading4>

        <Heading4>
          This Policy Document may be updated periodically by the Directorial
          Committee to reflect current needs and circumstances of the Group.
        </Heading4>
      </ContentSection>

      {/* <ButtonBlock
        buttonLabel="Book Your Tickets"
        href="https://www.quicket.co.za/events/251146-toad-of-toad-hall/#/"
        theme="primary"
      >
        Tickets for Toad of Toad Hall are now on sale on Quicket. Don't miss
        Dramabuzz Senior's third show at{" "}
        <strong>Gereformeerde Kerk Noordrand</strong>.
      </ButtonBlock> */}
    </PageWrapper>
  );
}
