import React, { Fragment } from "react";
import { AppWrapper } from "./App.styles";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as page from "./pages";
import { Footer } from "./components/footer/Footer";

function bookingLink() {
  window.open(
    `https://www.quicket.co.za/events/201872-pygmallion-the-story-that-inspired-my-fair-lady/?ref=link-campaign&lc=17026801`,
    "_top"
  );
  return <></>;
}

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <AppWrapper>
          <Switch>
            <Route path="/" exact={true} component={page.Home} />

            <Route path="/donate" component={page.DonatePage} />

            <Route path="/our-policies" component={page.OurPoliciesPage} />
            <Route path="/our-constitution" component={page.OurConstitutionPage} />

            <Route path="/structure/about-us" component={page.AboutUsPage} />
            <Route path="/structure/the-directors" component={page.TheDirectorsPage} />
            <Route path="/structure/the-team" component={page.TheTeamPage} />

            <Route path="/fees" component={page.FeeExplainerPage} />
            <Route path="/book" render={() => bookingLink()} />

            {/* Years */}
            <Route path="/2022" render={() => page.Years({ year: 2022 })} />
            <Route path="/2023" render={() => page.Years({ year: 2023 })} />
            <Route path="/2024" render={() => page.Years({ year: 2024 })} />

            {/* <Route path="**" component={page.Home} /> */}
          </Switch>
        </AppWrapper>

        <Footer />
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
